/*TIP*/
/*
  for better responsive design, set width of objects to a high % to fit mobile view
  then set a max-width with a fixed value to limit how big it will go
*/
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400..900;1,400..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
/*******************************************************
UNIVERSAL PAGE STYLING
*******************************************************/
.italic
{
  font-style: italic;
}

.md p
{
  margin-bottom: 1em;
}

.md a
{
  color: purple;
  font-style: italic;
  text-decoration: underline;
}

.md ul, ol 
{
  padding-left: 1.5em; 
  list-style: initial;
}

.md img
{
  width: 550px;
}

/*when in mobile*/
@media (max-width: 1200px)
{
  .md img
  {
    width: 280px;
  }
}


#b1 {
  margin: 0px;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  font-family: 'Roboto';
}

body 
{
  min-width: 400px;
  background-color: #efeed8;
  height: 100%;
}


.logo-con
{
  display: flex;
}

.spacing1
{
  width: 1.2vw;
}

.myname
{
  align-self: center;
  font-family: "Playfair Display";
  font-weight: 900;
  font-size: 77px;
}

.thelogo
{
  width: 90px;
  align-self: center;  
}

.thelogo a {
  display: flex;
  border-radius: 50%;
}

#logoPic
{
  width: 90px;
  border-radius: 50%;
  
}

.contact-deets
{
  align-self: center;
  font-size: min(max(1px, 2vw), 25px);
  
}

.contact-deets-row
{
  display: flex;
  align-items: center;
  height: 30px;
}

.contact-deets-row a
{
  height: 30px;
  display: flex;
  align-items: center;
  padding: 25px;
  border: 1.6px solid black;
  border-radius: 90px 90px 90px 90px;
  box-shadow: 5px 5px 0px 0px rgba(0,0,0,0.84);
}



.contact-deets-row a:active, #telegram-link:active
{
  transform: translateY(5px);
  transform: translateX(5px);
  box-shadow: none;
  transition: .2s ease-in-out;
}

#telegram-link
{
  display: flex;
  align-items: center;
  padding: 10px;
  border: 1.6px solid black;
  border-radius: 90px 90px 90px 90px;
  box-shadow: 5px 5px 0px 0px rgba(0,0,0,0.84);
  height: 30px;
  text-decoration: none !important;
}

#mobile-tele-me
{
  align-self: center;
  font-family: 'Roboto';
}


.page {
  width: 100%;
  background: #fdfdfd;
  font-family: 'Roboto';
  font-size: 16px;
}

.header-spacing
{
  height: 125px;
}

header {
  position: fixed;
  top: 0;
  display: flex;
  width: 100%;
  height: 125px;
  max-height: 320px;
  background: #efeed8;
  color: black;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.headerCon {
  width: 85%;
  max-width: 1200px;
  margin: auto;
  display: flex;
  justify-content: space-between;
}

.mobilemenu-photo-con
{
  display: flex;
}

.mobilemenu-photo-con a
{
  margin-left: 10px;
  align-self: center;
  text-decoration: underline;
}


#telelogo
{
  height: 15px;
  width: 15px;
  align-self: center;
  margin-left: 5px;
}

#telelogotop
{
  height: 20px;
  width: 20px;
  align-self: center;
  margin-left: 5px;
}


.button.open .icon-bar:nth-of-type(1) {
  transform: translate3d(0,8px,0) rotate(45deg);
}
.button.open .icon-bar:nth-of-type(2) {
  opacity: 0;
}
.button.open .icon-bar:nth-of-type(3) {
  transform: translate3d(0,-8px,0) rotate(-45deg);
}






#nav-content {
  margin-top: 60px;
  padding: 20px;
  max-width: 350px;
  background-color: #efeed8;
}






.button {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 1202;
  border: 0;
  background: transparent;
  border-radius: 0;
  height: 62px;
  width: 40px !important;
  min-width: unset !important; 
  cursor: pointer;
  pointer-events: auto;
  margin-left: 3vw !important;
  touch-action: manipulation;
}
.icon-bar {
  display: block;
  width: 100%;
  height: 3px;
  background: darkgrey;
  transition: .3s;
}
.icon-bar + .icon-bar {
  margin-top: 5px;
}

/* new hamburger css */




.normal-menu {
  border-top: 62px;
  width: 465px;
  display: flex;
  align-items: center;
}

.normal-menu ul {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.normal-navs  {
  color: mediumslateblue;
  text-decoration: none;
}

.normal-navs:hover {
  color: brown;
}

.normal-menu li {
  display: inline;
}


.active {
  text-decoration: underline;
  color: brown;
}



/*when in mobile*/
@media (max-width: 1200px) {
  .contact-deets {
    display: none;
  }

  .thelogo, .spacing1 {
    display: none;
  }
  
  .logo-con {
    margin: auto;
  }


  .myname
  {
    font-size: 45px;
  }

  header
  {
    height: 62px;
    z-index: 1201;
  }

  .header-spacing
  {
    height: 25px;
  }

}


/*when in desktop*/
@media (min-width: 1200px) {
  #nav-container {
    display: none;
  }

  header {
    justify-content: space-between;
  }

  .thelogo {
    margin: unset;
  }
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>*/

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  text-decoration: none;
}

a
{
  color: unset;
}


#btmBar {
  margin-top: 90px;
  width: 100%;
  background-color: rgb(44, 41, 41);
  position: relative;
  bottom: 0;
}

.stickers-con {
  width: 90%;
  max-width: 900px;
  margin: auto;
  top: 8px;
  position: relative;
  text-align: center;
}

#stickers {
  width: 100%;
}

#creds {
  color: darkgrey;
  width: 80%;
  margin: auto;
  text-align: center;
  padding-top: 30px;
  padding-bottom: 30px;
  font-family: "Times New Roman";
  font-size: 14px;
}



/*******************************************************
ABOUT PAGE STYLING
*******************************************************/

.aboutpage-con
{
  max-width: 1800px;
  margin: auto;
}

.about-con
{
  display: flex;
  flex-direction: column;
  min-height: 100%;
  margin-left: 20%;
  width: 80%;
  padding-left: 30px;
  padding-right: 10%; 

}

.sidebar
{
  width: 20%;
  display: flex;
  flex-direction: column;
  text-align: right;
  font-size: 30px;
  font-weight: 500;
  position: fixed;
  max-width: 360px;
  top: 125px;
}

.sidebar ul li
{
  list-style-type: none;
  margin: 5px 0px;
}

.bignavs:hover
{
  text-decoration: underline;
  color: brown;
  transition: .4s ease-in-out;
}


.title
{
  display: none;
}


#desc {
  position: relative;
  padding-bottom: 200px;
  display: flex;
  flex-direction: row-reverse;
}

#abt {
  border-top: 2px solid black;
  flex: 1;
  width: 80%;
  font-size: 16px;
  padding-top: 30px;
  line-height: 1.6;
  display: flex;
  flex-direction: column;
}



/*when in mobile*/
@media (max-width: 1200px)
{
  .sidebar
  {
    display: none;
  }

  #desc
  {
    display: block;
  }

  #abt
  {
    line-height: 1.6;
    padding-top: 20px;
    width: 95%;
    margin: auto;
  }

  .about-con
  {
    margin-left: unset;
    display: flex;
    flex-direction: column;
    margin: auto;
    padding: unset;
    padding-top: 50px;
    width: 90%;
  }

  .title
  {
    width: 95%;
    display: unset;
    margin: auto;
    font-weight: 500;
    font-size: 32px;
  }
}


/*******************************************************
WHAT I OFFER PAGE STYLING
*******************************************************/

.bubble-row
{
  display: flex;
  flex-wrap: wrap;
}

.bubble
{
  font-weight: 500;
  margin: 8px 2px;
  padding: 10px 20px;
  border: 2px solid black;
  border-radius: 10px;
  text-wrap: nowrap;
}



/*******************************************************
CERTS PAGE STYLING
*******************************************************/

.timeline {
  position: relative;
  max-width: 600px;
  margin: 0 auto;
  padding: 20px 0; /* Adjusted padding to avoid overlapping lines */
}
.timeline::before {
  content: '';
  position: absolute;
  left: -15px; /* Positioning the line */
  top: 20px; /* Adjusting the starting point */
  bottom: 0;
  width: 2px;
  background-color: #000;
}
.timeline-entry {
  margin: 20px 0;
  padding-left: 20px;
  position: relative;
}
.timeline-entry::before {
  content: '';
  position: absolute;
  left: -15px; /* Aligning the horizontal line with the vertical line */
  top: 11px; /* Centering the horizontal line relative to the text */
  width: 18px; /* Length of the horizontal line */
  height: 2px;
  background-color: #000;
}
.timeline-entry h3, .timeline-entry p {
  margin: 0;
  padding: 0;
}
.timeline-entry h3 {
  font-size: 1em;
  font-weight: bold;
}
.timeline-entry p {
  font-size: 0.9em;
}



/*******************************************************
PROJECTS PAGE STYLING
*******************************************************/

.proj-pic
{
  width: 280px;
}

.proj-con
{
  display: flex;
  flex-wrap: wrap;
}

.proj-unit
{
  margin: 10px 10px;
  padding: 15px;
  border: solid 1px black;
  border-radius: 5px;
}

.title2
{
  font-weight: 500;
  font-size: 32px;
}

.goodList
{
  padding-left: 20px;
}

/*when in mobile*/
@media (max-width: 1200px)
{
  .title2
  {
    display: none;
  }
}



/*******************************************************
TENT CARD MAKER PAGE STYLING
*******************************************************/
.upload-box
{
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid black;
  padding: 5px;
  width: 300px;
}

.choose-file-box
{
  display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: space-between;
    height: 80px;
}

.choose-file-btn
{
  width: 100%;
}

.upl-file-btn
{
  width: 100%;
  padding: 2px;
}

.downl_btn
{
  margin: 20px 0px;
}

.downl_btn a
{
  border: 1px solid black;
  padding: 5px 20px;
  border-radius: 5px;
}